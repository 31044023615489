export const GlobalVariable = {

    SITE_NAME: 'Vebkard Admin',
    PATTERNS: {
        email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    },
    tokenKey: 'supr_student_token',

    languageType :1 ,

};

export const CONSTANT = {
    SIDEBARLIST: [
        //  {path: 'dashboard', name: 'Dashboard', icon: 'fa fa-flag'},

        {path: 'owners', name: 'Business Owners', icon: 'fa fa-users'},
        
        {path: 'business-types', name: 'Business Types', icon: 'fa fa-font-awesome'},
        
        {path: 'tutorials', name: 'Tutorials', icon: 'fa fa-anchor'},
     
        {path: 'plans', name: 'Plans', icon: 'fa fa-money'},
         {path: 'banner', name: 'Banner', icon: 'fa fa-map-signs'},
         {path: 'colors', name: 'Color Theme', icon: 'fa fa-paint-brush'},
        //  {path: 'createAdmin', name: 'Sub Admin', icon: 'fa-solid fa-person'},

        {path: 'feedback', name: 'Feedback', icon: 'fa fa-handshake-o'},
        {path: 'support', name: 'Support', icon: 'fa fa-support'},
        {path: 'report', name: 'Report issue', icon: 'fa fa-bug'},

        
        
        {path: 'version', name: 'Versions', icon: 'fa fa-code-fork'},




        {path: 'version-release-notes', name: 'Release Notes', icon: 'fa fa-binoculars'} ,

        {path: 'terms', name: 'Terms & Conditions', icon: 'fa fa-th'},

        {path: 'policy', name: 'Privacy Policy', icon: 'fa fa-flag'},
        {path: 'aboutUs', name: 'About Us', icon: 'fa fa-thumb-tack'},

        {path: 'general', name: 'General Settings', icon: 'fa fa-shopping-cart'},
       
        // {path: 'language', name: 'Language', icon: 'fa fa-language'},


        // {path: 'plans', name: 'Plans', icon: 'fa fa-podcast'},

   

        // {path: 'nft', name: 'NFT', icon: 'fa fa-linode'},

        // {path: 'states', name: 'States', icon: 'fa fa-font-awesome'},
        
        // {path: 'schools', name: 'Schools', icon: 'fa fa-graduation-cap'},
        
        // {path: 'grades', name: 'Grades', icon: 'fa fa-compass'},
        // {path: 'interests', name: 'Interests', icon: 'fa fa-bath'},
        // {path: 'subjects', name: 'Subjects', icon: 'fa fa-yelp'},
        // {path: 'topics', name: 'Topics', icon: 'fa fa-futbol-o'},
        // {path: 'requests', name: 'Refferal Requests', icon: 'fa fa-handshake-o'},
        // {path: 'referred-users', name: 'Referred Users', icon: 'fa fa-snowflake-o'},
        // {path: 'refferal-code', name: 'Refferal Codes', icon: 'fa fa-send'},
        // {path: 'payment-details', name: 'Payment Details', icon: 'fa fa-money'},
        // {path: 'versions', name: 'Versions', icon: 'fa fa-code-fork'},
        
        // {path: 'aboutUs', name: 'About Us', icon: 'fa fa-thumb-tack'},
        // {path: 'gifts', name: 'Gifts', icon: 'fa fa-tty'},
        
       

        // {path: 'policy', name: 'Privacy Policy', icon: 'fa fa-flag'}
    ]
};

