export const SuccessErrorConst = {

    loginAgain: 'Please login Again!',
    addedSuccess: 'Added Successfully!',
    updatedSuccess: 'Updated Successfully!',
    deleteSuccess: 'Deleted Successfully!',
    approveSuccess: 'Updated Successfully!',
    messageSuccess: 'Message sent successfully!',
    required: 'This field is required.',
    validEmail: 'Please enter valid email!.',
    noImageAdded: 'Please add atleast 1 image!.' ,
    description : 'Max 80 characters allowed',
    title : 'Max 30 characters allowed',
    closeseccess: "Closed Successfully",
    planactivated: "Plan Activated Successfully",
    download: 'Downloaded Successfully!',
    AllDownload: 'All Downloaded Successfully!',
    upload:'this file is not upload',
    uploads: 'uploaded Successfully!',

};
