import { Pipe, PipeTransform } from '@angular/core';
import { TranslateServicesService } from 'src/app/services/translate/translate-services.service';

@Pipe({
  name: 'translate',
  pure:false
})
export class TranslatePipe implements PipeTransform {

  constructor(private translate: TranslateServicesService) {}
  transform(key: any): any {
    return this.translate.data[key] || key;
  }

}

export { TranslateServicesService };
