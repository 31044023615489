import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// guards
import { AuthGuard } from './services/guards/auth.guard';
import { LayoutGuard } from './services/guards/layout.guard';

const routes: Routes = [
    {
        path: '', redirectTo: '/login', pathMatch: 'full'
    },
    {
        path: 'login',
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
        data: {title: 'Login', breadcrumb: 'Login'}, canActivate: [AuthGuard]
    },
    {
        path: 'reset-password-respondent',
        loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordModule),
        data: {title: 'Reset Password Respondent', breadcrumb: 'Reset Password Respondent'}
    },
    {
        path: 'reset-password-client',
        loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordModule),
        data: {title: 'Reset Password Client', breadcrumb: 'Reset Password Client'}
    },
    {
        path: '',
        loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule),
        canActivate: [LayoutGuard]
    },
    {
        path: '**', redirectTo: '/login'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
