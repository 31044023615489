import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class TranslateServicesService {

  setDefaultLang(arg0: string) {
    throw new Error('Method not implemented.');
}

data: any = {};

constructor(private http: HttpClient) { }

use(lang: string): Promise<{}> {
  return new Promise<{}>((resolve, reject) => {


    const langPath = `assets/json/${lang || 'en'}.json`;
    this.http.get<{}>(langPath).subscribe(
      translation => {
        this.data = Object.assign({}, translation || {});
        resolve(this.data);

      },
      error => {
        this.data = {};
        resolve(this.data);
      }
    );
  });
}
}

